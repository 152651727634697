import axios from 'axios';
import { reportError } from './errorReporter'

const host = 'https://tiktokvoice.net'
export function reportWebVital(metric) {
  try {
    const uri = `${host}/lapi/webvitals`;
    const formData = new FormData();
    formData.append('metric_id', metric.id);
    formData.append('metric_name', metric.name);
    formData.append('metric_value', metric.value);
    formData.append('metric_delta', metric.delta);
    formData.append('metric_rating', metric.rating);
    formData.append('metric_navigation', metric.navigationType);
    formData.append('attribution', JSON.stringify(metric.attribution));

    axios.post(uri, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  } catch (error) {
    reportError(error, 'reportWebVital');
  }
}


      // Add Web Vitals reporting
      // console.log("report webVital 1")
      // 暂时不上报了
      // try {
      //   onINP((metric) => this.reportWebVital(metric));
      // } catch (error) {
      //   reportError(error, 'Failed to initialize INP monitoring:');
      // }