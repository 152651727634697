export const LANGUAGE_OPTIONS = [
    { value: 'en', label: 'English' },
    { value: 'ja', label: '日本語' },
    { value: 'zh', label: '中文' },
    { value: 'zh-tw', label: '繁體中文' },
    { value: 'fr', label: 'Français' },
    { value: 'de', label: 'Deutsch' },
    { value: 'es', label: 'Español' },
    { value: 'ru', label: 'Русский' },
    { value: 'pt', label: 'Português' },
    { value: 'ko', label: '한국어' },
    { value: 'ar', label: 'العربية'},
    { value: 'it', label: 'Italiano' },
    { value: 'nl', label: 'Nederlands' },
    { value: 'pl', label: 'Polski' },
    { value: 'tr', label: 'Türkçe' },
    { value: 'uk', label: 'Українська' },
    { value: 'vi', label: 'Tiếng Việt' },
    { value: 'th', label: 'ภาษาไทย' },
    { value: 'hi', label: 'हिन्दी' },
    { value: 'ur', label: 'اردو' },
    { value: 'ms', label: 'Bahasa Melayu' },
];