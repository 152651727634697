<template>
<div class="container">
  <div class="top" id="bloc-0">
    <voice-header></voice-header>
  </div>
  <div class="pricing-container">
    <div class="pricing-header">
       <div class="pricing-title">
         <h1>{{ $t('pricing.main_title') }}</h1>
         <p v-if="$store.state.lang === 'zh'" class="china-payment-notice" @click="showMainlandPopup">
           使用 
           <span class="payment-method">
             <img src="/ssr/img/payment/alipay.svg" alt="支付宝" class="payment-icon" height="32px" width="32px">
           </span>支付宝或
           <span class="payment-method">
             <img src="/ssr/img/payment/wechat.svg" alt="微信支付" class="payment-icon" height="32px" width="32px">
           </span>微信支付? 点此付款 
           <svg class="arrow-icon" width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
           </svg>
         </p>
       </div>
       
       <div class="billing-toggle-wrapper">
         <div class="billing-toggle">
           <div class="toggle-text-left">
             <span class="toggle-text">{{ $t('pricing.period_month') }}</span>
           </div>
           <div class="switch-container">
             <label class="switch">
               <input type="checkbox" v-model="isYearly">
               <span class="slider round"></span>
             </label>
           </div>
           <div class="toggle-text-right">
             <span class="toggle-text">{{ $t('pricing.period_year') }}</span>
             <span class="yearly-discount" :class="{ 'monthly': !isYearly }">{{ $t('pricing.discount_quantity') }}</span>
           </div>
         </div>
       </div>
    </div>
    <div class="pricing-plans">
      <div v-for="plan in currentPlans" :key="plan.name" class="plan" :class="{ 'popular': plan.isPopular }">
        <div v-if="plan.isPopular" class="popular-tag">
          <div class="popular-tag-row">
            <div class="sale-title">
              <div class="offer-ends">{{ $t('pricing.black_friday_sale') }}</div>
            </div>
            <div class="timer">
              <span class="time-block">{{ minutes }}</span>
              <span class="time-separator">:</span>
              <span class="time-block">{{ seconds }}</span>
              <span class="time-separator">:</span>
              <span class="time-block milliseconds">{{ milliseconds }}</span>
            </div>
          </div>
          <div class="countdown-timer">
          </div>
        </div>
        <h2>{{ plan.name }}</h2>
        <p>{{ plan.description }}</p>
        <div class="price-container">
          <div class="price-row">
            <span class="price-number">${{ plan.price }}</span>
            <span class="period">/{{ $t('pricing.period_month_short') }}</span>
          </div>
          <div class="billed-yearly-container">
            <p v-if="plan.price > 0.1" class="billed-yearly" id="starter-billed">
              <span v-if="isYearly && plan.price_year_total">
                ${{ plan.price_year_total }} {{ $t('pricing.billed_year') }}
                <span class="original-price">${{ plan.price_year_total * 2 }}</span>
              </span>
              <span v-else-if="plan.price > 0.1">
                <span class="original-price">${{ plan.price * 2 }}</span>
              </span>
              <span v-else>&nbsp;</span>
            </p>
          </div>
        </div>
	      <div v-if="plan.price > 0.1">
          <button 
            class="get-started-btn" 
            :class="{ 'popular-btn': plan.isPopular }" 
            @click.prevent="createCheckoutSession(plan)"
          >
            {{ $t('pricing.buy_btntext') }}
          </button>
        </div>
        <div class="features">
          <ul>
            <li v-for="feature in plan.features" :key="feature" :class="{ 'highlight-feature': feature.includes('1000 characters') || feature.includes('5 generations') }">
              {{ feature }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <GoogleSignInModal 
       :visible.sync="isSignInShow"
       @credential-response="handleCredentialResponse"
       @close="closePriceSignIn"
     />
    <PopupMainland 
      :visible="showMainlandPayment"
      :onCheckout="createCheckoutSession"
      @close="closeMainlandPopup"
    />
  </div>
  
  <div class="brands-section">
    <h2 class="brands-title">{{ $t('pricing.brands_title') }}</h2>
    <div class="brands-carousel">
      <div class="brands-track">
        <div class="brand-item">
          <img src="/ssr/img/brands/discord.svg"  height="40px" width="40px" alt="Discord" />
        </div>
        <div class="brand-item">
          <img src="/ssr/img/brands/facebook.svg" height="40px" width="40px" alt="Facebook" />
        </div>
        <div class="brand-item">
          <img src="/ssr/img/brands/instagram.svg" height="40px" width="40px" alt="Instagram" />
        </div>
        <div class="brand-item">
          <img src="/ssr/img/brands/snapchat.svg" height="40px" width="40px" alt="Snapchat" />
        </div>
        <div class="brand-item">
          <img src="/ssr/img/brands/tiktok.svg" height="40px" width="40px" alt="TikTok" />
        </div>
        <div class="brand-item">
          <img src="/ssr/img/brands/twitch.svg" height="40px" width="40px" alt="Twitch" />
        </div>
        <div class="brand-item">
          <img src="/ssr/img/brands/vimeo.svg" height="40px" width="40px" alt="Vimeo" />
        </div>
        <div class="brand-item">
          <img src="/ssr/img/brands/youtube.svg" height="40px" width="40px" alt="YouTube" />
        </div>
      </div>
    </div>
  </div>

  <div class="testimonials-section">
    <h2 class="testimonials-title">{{ $t('pricing.testimonials_title') }}</h2>
    <div class="rating-container">
      <div class="stars">
        <span class="star">★</span>
        <span class="star">★</span>
        <span class="star">★</span>
        <span class="star">★</span>
        <span class="star last">★</span>
      </div>
      <span class="rating-text">4.8</span>
    </div>
    <div class="testimonial-grid">
      <div class="testimonial-card">
        <div class="testimonial-header">
          <img src="/ssr/img/avatars/user1.jpg" alt="User avatar"  height="48px" width="48px" class="avatar">
          <div class="user-info">
            <div class="user-name">Emma Thompson</div>
            <div class="user-title">Content Creator</div>
          </div>
        </div>
        <p class="testimonial-text">"The emotional depth of the AI voices is incredible! My storytelling videos now connect with viewers on a whole new level. Engagement up by 280% and gained 15K followers in just 3 weeks. Game-changer!"</p>
      </div>
      
      <div class="testimonial-card">
        <div class="testimonial-header">
          <img src="/ssr/img/avatars/user2.jpg" alt="User avatar"  height="48px" width="48px" class="avatar">
          <div class="user-info">
            <div class="user-name">David Miller</div>
            <div class="user-title">Audiobook Producer</div>
          </div>
        </div>
        <p class="testimonial-text">"The natural flow and emotional range of these AI voices amazed me. My latest audiobook hit $6,000 in sales within the first month. The quality rivals professional voice actors!"</p>
      </div>
      
      <div class="testimonial-card">
        <div class="testimonial-header">
          <img src="/ssr/img/avatars/user3.jpg" alt="User avatar"  height="48px" width="48px" class="avatar">
          <div class="user-info">
            <div class="user-name">Rachel Anderson</div>
            <div class="user-title">E-Learning Director</div>
          </div>
        </div>
        <p class="testimonial-text">"Student engagement skyrocketed after implementing these AI voices in our courses. Completion rates up 45%, and students consistently praise the natural, engaging delivery of our content."</p>
      </div>

      <div class="testimonial-card">
        <div class="testimonial-header">
          <img src="/ssr/img/avatars/user4.jpg" alt="User avatar"  height="48px" width="48px" class="avatar">
          <div class="user-info">
            <div class="user-name">Destiny Williams</div>
            <div class="user-title">Marketing Director</div>
          </div>
        </div>
        <p class="testimonial-text">"Our ad campaign performance doubled after switching to these AI voices. The emotional connection they create with audiences is remarkable. ROI increased by 180% across all platforms."</p>
      </div>

      <div class="testimonial-card">
        <div class="testimonial-header">
          <img src="/ssr/img/avatars/user5.jpg" alt="User avatar"  height="48px" width="48px" class="avatar">
          <div class="user-info">
            <div class="user-name">Yuki Tanaka</div>
            <div class="user-title">ポッドキャスト制作者</div>
          </div>
        </div>
        <p class="testimonial-text">"AIボイスの自然な抑揚と感情表現に驚きました。リスナーからの反応が劇的に改善し、わずか2ヶ月で視聴者数が3倍に。制作時間も大幅に短縮できて、本当に助かっています。"</p>
      </div>

      <div class="testimonial-card">
        <div class="testimonial-header">
          <img src="/ssr/img/avatars/user6.jpg" alt="User avatar"  height="48px" width="48px" class="avatar">
          <div class="user-info">
            <div class="user-name">Carlos Rodríguez</div>
            <div class="user-title">Creador de Contenido</div>
          </div>
        </div>
        <p class="testimonial-text">"¡Increíble herramienta! La calidad emocional de las voces transformó mis videos. El engagement aumentó un 200% y mis seguidores se duplicaron en un mes. La mejor inversión para mi contenido."</p>
      </div>
    </div>
  </div>
  <div class="footer" id="pricing-footer">
    <div>
      <voice-footer></voice-footer>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex';
import { defineAsyncComponent } from 'vue'
import VoiceHeader from '../components/VoiceHeader.vue';
import { handleGoogleAuth } from '../utils/auth'
import { setCookie, delCookie, getCookie } from '../utils/cookies';
import { reportError } from '../utils/errorReporter'
import { trackAction } from '../utils/actionReporter'
import PopupMainland from '../components/PopupMainland.vue'
import GoogleSignInModal from '../components/GoogleSignInModal.vue'

export default {
  name: 'pricing',
  components: {
    VoiceHeader,
    GoogleSignInModal,
    PopupMainland,
    VoiceFooter: defineAsyncComponent({
            loader: () => import('../components/VoiceFooterIndex.vue'),
            timeout: 2000,
    }),
  },
  data() {
    return {
      host: "https://tiktokvoice.net",
      isSignInShow: false,
      pendingPlan: null, 
      isLoginInProgress: false, 
      isYearly: false,
      monthlyPlans: [
        {
          name: this.$i18n.t('pricing.sub_free_name'),
          description: this.$i18n.t('pricing.sub_free_desc'),
          price: '0',
          isPopular: false,
          type_name: 'free_month',
          subscript_type: '0',
          features: this.$i18n.t('pricing.sub_free_content'),
        },
        {
          name: this.$i18n.t('pricing.sub_starter_name'),
          description: this.$i18n.t('pricing.sub_starter_desc'),
          price: '7.9',
          isPopular: true,
          type_name: 'starter_month',
          features: this.$i18n.t('pricing.sub_starter_content'),
        },
        {
          name: this.$i18n.t('pricing.sub_pro_name'),
          description: this.$i18n.t('pricing.sub_pro_desc'),
          price: '21.9',
          isPopular: false,
          type_name: 'pro_month',
          features: this.$i18n.t('pricing.sub_pro_content'),
        }
      ],
      yearlyPlans: [
        {
          name: this.$i18n.t('pricing.sub_free_name'),
          description: this.$i18n.t('pricing.sub_free_desc'),
          price: '0',
          isPopular: false,
          type_name: 'free_year',
          features: this.$i18n.t('pricing.sub_free_content'),
        },
        {
          name: this.$i18n.t('pricing.sub_starter_name'),
          description: this.$i18n.t('pricing.sub_starter_desc'),
          price: '4.9',
          price_year_total: '58.8',
          isPopular: true,
          type_name: 'starter_year',
          features: this.$i18n.t('pricing.sub_starter_content'),
        },
        {
          name: this.$i18n.t('pricing.sub_pro_name'),
          description: this.$i18n.t('pricing.sub_pro_desc'),
          price: '12.9',
          price_year_total: '154.8',
          isPopular: false,
          type_name: 'pro_year',
          features: this.$i18n.t('pricing.sub_pro_content'),
        }
      ],
      minutes: '03',
      seconds: '00',
      milliseconds: '0',
      countdownInterval: null,
      showMilliseconds: true,
      errorHandler: null,
      rejectionHandler: null,
      showMainlandPayment: false,
    }
  },
  computed: {
    currentPlans() {
      return this.isYearly ? this.yearlyPlans : this.monthlyPlans;
    },
    ...mapGetters(['isLoggedIn', 'currentUser'])
  },
  head() {
    return {
      'title': this.$i18n.t('pricing.pricing_title'),
      'keywords': this.$i18n.t('txt2voice.keywords'),
      'description': this.$i18n.t('pricing.pricing_desc'),
    }
  },
  asyncData: function ({ store, route }) {
    // console.log('home state count:' + store.state.count)
    return store.dispatch("fetchData")
  },
  methods: {
    // 新增重置方法
    resetSignInState() {
      this.isSignInShow = false;
      this.isLoginInProgress = false;
      this.pendingPlan = null;
    },
    closePriceSignIn() {
      this.resetSignInState();
      // 给系统一点时间完全清理状态
      setTimeout(() => {
        this.isLoginInProgress = false;
      }, 100);
    },
    async handleCredentialResponse(response) {
      const apiUrl = this.host + "/lapi/auth/google"
      // console.log("handleCredentialResponse 11, response:", response)
      const success = await handleGoogleAuth.handleCredentialResponse(response, this.$store, apiUrl)
      // console.log("handleCredentialResponse 22, success:", success)
      if (success) {
        if (this.pendingPlan) {
          const plan = this.pendingPlan
          this.resetSignInState();
          await this.createCheckoutSession(plan)
          trackAction({
            email: this.currentUser?.email,
            action: 'pricing-login-success',
            domain: 'tiktokvoice.net',
            modelcat: plan.type_name,
            modelname: ''
          });
        }
        this.resetSignInState();   
      } else {
        this.resetSignInState();
        reportError(new Error('Pricing login Authentication failed'), 'handleCredentialResponse failed')
      }
    },
    async createCheckoutSession(plan) {
      if (!plan || !plan.type_name) {
        reportError(new Error('Invalid plan data'), "createCheckoutSession");
        return;
      }

      if (this.isLoginInProgress) {
        return;
      }

      if (!this.isLoggedIn) {
        this.showMainlandPayment = false
        this.isLoginInProgress = true;
        this.pendingPlan = plan;
        this.isSignInShow = true;
        trackAction({
          email: "",
          action: 'pricing-popup-login',
          domain: 'tiktokvoice.net',
          modelcat: plan.type_name,
          modelname: ''
        });
        return;
      }

      const uri = this.host + '/lapi/cpaysess';
      try {
        const email = this.currentUser?.email || '';
        const formData = new FormData();
        formData.append('type_name', plan.type_name);
        formData.append('email', email);
        var success_url = '/' + this.$store.state.lang + '/pay/success'
        formData.append('success_url', success_url)
        var cancel_url = '/' + this.$store.state.lang + '/pricing'
        formData.append('cancel_url', cancel_url)
        const headers = {
          'Content-Type': 'multipart/form-data'
        }
        //console.log('start post!')
        const res = await axios.post(uri, formData, headers)
        // 处理响应, 例如重定向Stripe Checkout页面
        // console.log('respose ret:', res.data.ret)
        // console.log('respose url:', res.data.url)
        if (res.data.ret === 0){
          window.location.href = res.data.url
          trackAction({
            email: this.currentUser?.email,
            action: 'pricing-checkout-success',
            domain: 'tiktokvoice.net',
            modelcat: plan.type_name,
            modelname: ''
          });
        } else {
          //console.log('msg:' + res.data.msg)
          reportError(new Error(res.data.msg), "createCheckoutSession")
        }
      } catch (error) {
        // console.error('Error creating checkout session:', error)
        reportError(error, "createCheckoutSession error")
      }
    },
    startCountdown() {
      let totalMilliseconds = 180000; // 3 minutes in milliseconds
      this.countdownInterval = setInterval(() => {
        if (totalMilliseconds > 0) {
          totalMilliseconds -= 50; // Decrease by 50ms each time
          const minutes = Math.floor(totalMilliseconds / 60000);
          const seconds = Math.floor((totalMilliseconds % 60000) / 1000);
          const ms = Math.floor((totalMilliseconds % 1000) / 100); // Only get first digit of milliseconds
          
          this.minutes = String(minutes).padStart(2, '0');
          this.seconds = String(seconds).padStart(2, '0');
          this.milliseconds = String(ms);
        } else {
          clearInterval(this.countdownInterval);
          this.minutes = '03';
          this.seconds = '00';
          this.milliseconds = '0';
          this.startCountdown();
        }
      }, 50); // Update every 50ms for smoother animation
    },
    showMainlandPopup() {
      this.showMainlandPayment = true
    },
    closeMainlandPopup() {
      this.showMainlandPayment = false
    },
    handleMainlandPayment(planId) {
      // 处理大陆支付逻辑
      this.showMainlandPayment = false
    }
  },
  mounted() {
    this.startCountdown()
    
    // 复制品牌轮播内容以实现无缝效果
    const brandsTrack = document.querySelector('.brands-track')
    if (brandsTrack) {
      const items = brandsTrack.innerHTML
      brandsTrack.innerHTML = items + items
    }
           // 使用箭头函数保持this上下文
    this.errorHandler = (event) => reportError(event.error, 'window.error');
    this.rejectionHandler = (event) => reportError(event.reason, 'unhandledrejection');
    // 添加全局错误监听
    window.addEventListener('error', this.errorHandler);
    window.addEventListener('unhandledrejection', this.rejectionHandler);
  },
  beforeDestroy() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
    if (this.errorHandler) {
      window.removeEventListener('error', this.errorHandler);
    }
    if (this.rejectionHandler) {
      window.removeEventListener('unhandledrejection', this.rejectionHandler);
    }
  }
}
</script>

<style scoped>
@import url('/ssr/css/pricing_third.css');

.pricing-container { max-width: 1000px; width: 100%; margin: 20px auto; padding: 10px; }
.pricing-header { display: flex; flex-direction: column; align-items: center; margin-bottom: 40px; }
.pricing-title { text-align: center; margin-bottom: 20px; width: 100%; display: block; }
.pricing-title h1 { font-size: 28px; font-weight: 700; color: #333; line-height: 1.2; margin: 0; }
.payment-method { display: inline-flex; align-items: center; margin: 0 4px; vertical-align: middle; }
.china-payment-notice .arrow-icon { margin-left: 4px; vertical-align: middle; transition: transform 0.3s ease; }
.china-payment-notice:hover .arrow-icon { transform: translateX(3px); }

.billing-toggle-wrapper { display: flex; justify-content: center; align-items: center; width: 100%; margin: 20px auto; padding: 0; }
.billing-toggle { display: flex; align-items: center; position: relative; padding: 5px; border-radius: 20px; height: 38px; width: 100%; max-width: 420px; }
.toggle-text-left { flex: 1; text-align: right; padding-right: 16px; min-width: 80px; }
.switch-container { flex: 0 0 60px; display: flex; justify-content: center; align-items: center; }
.toggle-text-right { flex: 1; display: flex; align-items: center; padding-left: 16px; min-width: 140px; }
.toggle-text { font-weight: bold; font-size: 14px; white-space: nowrap; color: #333; }
.switch { position: relative; display: inline-block; width: 60px; height: 28px; }
.switch input { opacity: 0; width: 0; height: 0; }
.toggle-text-right .yearly-discount { background: #FF4D4F; color: white; padding: 4px 8px; border-radius: 12px; font-size: 12px; font-weight: 500; white-space: nowrap; margin-left: 8px; }
.toggle-text-right .yearly-discount:hover { opacity: 0.9; }
.toggle-text-right .yearly-discount.monthly { background: #f5f5f5; color: #666; text-decoration: line-through; }
.slider { position: absolute; cursor: pointer; top: 0; left: 0; right: 0; bottom: 0; background-color: #ccc; transition: .4s; border-radius: 34px; }
.slider:before { position: absolute; content: ""; height: 24px; width: 24px; left: 2px; bottom: 2px; background-color: white; transition: .4s; border-radius: 50%; }
input:checked + .slider { background: linear-gradient(to right, #ff6b6b, #a66cff); opacity: 0.9; }
input:checked + .slider:hover { opacity: 1; }
input:checked + .slider:before { transform: translateX(32px); }

.pricing-plans { display: flex; flex-wrap: wrap; justify-content: space-between; gap: 20px; margin-bottom: 80px; }
.plan { flex: 1 1 250px; position: relative; max-width: 300px; margin-bottom: 20px; padding: 30px; border-radius: 8px; background-color: white; box-shadow: none; transition: all 0.3s ease; border: 1px solid #e0e0e0; }
.plan[data-v-69097ff2]:first-child, .features ul[data-v-69097ff2] { background-color: #f8f9fa; }
.plan[data-v-69097ff2]:first-child .features ul[data-v-69097ff2] { background-color: transparent; }

.popular { border: none; position: relative; background: white; transform: scale(1.1); box-shadow: 0 8px 24px rgba(0,0,0,0.12); z-index: 1; }
.popular::before { content: ''; position: absolute; top: 0; left: 0; right: 0; bottom: 0; border-radius: 8px; padding: 1px; background: linear-gradient(to right, #ff6b6b, #a66cff); -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0); mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0); -webkit-mask-composite: xor; mask-composite: exclude; pointer-events: none; }
.popular-tag { position: absolute; top: 0; left: 0; right: 0; background: linear-gradient(to right, #ff6b6b, #a66cff); color: white; text-align: center; padding: 8px 12px; font-weight: 500; border-radius: 8px 8px 0 0; display: flex; flex-direction: column; align-items: center; gap: 4px; }
.popular-tag-row { display: flex; flex-direction: column; align-items: center; gap: 4px; }

.get-started-btn { display: block; width: 100%; background: linear-gradient(to right, #ff6b6b, #a66cff); color: white; text-decoration: none; padding: 15px 0; border-radius: 8px; font-weight: bold; text-align: center; transition: all 0.3s ease; box-shadow: 0 4px 6px rgba(0,0,0,0.1); margin: 12px 0; border: none; }
.get-started-btn:hover { opacity: 0.9; transform: translateY(-2px); box-shadow: 0 6px 12px rgba(0,0,0,0.15); }
.popular-btn { background: linear-gradient(to right, #ff6b6b, #a66cff); }

.timer { display: flex; align-items: center; justify-content: center; gap: 0; color: #000; padding: 2px; border-radius: 4px; }
.time-block { background: rgba(255, 255, 255, 0.6); padding: 2px 4px; border-radius: 3px; font-weight: bold; font-size: 14px; text-align: center; color: #000; font-family: 'Courier New', monospace; width: 24px; display: inline-block; }
.time-block.milliseconds { font-size: 14px; width: 26px; padding: 2px 2px; background: rgba(255, 255, 255, 0.6); }
.time-separator { font-weight: bold; font-size: 14px; color: rgba(0, 0, 0, 0.8); width: 6px; text-align: center; display: inline-block; margin: 0 1px; }
.plan h2 { margin-top: 40px; margin-bottom: 16px; font-size: 28px; }
.plan p { margin-top: 0; margin-bottom: 20px; color: #6c757d; }
.price-container { min-height: 60px; display: flex; flex-direction: column; align-items: center; justify-content: center; margin-bottom: 12px; }
.price-row { display: flex; align-items: baseline; justify-content: center; white-space: nowrap; margin-bottom: 4px; }
.price-number { font-size: 2.5em; font-weight: bold; line-height: 1; margin: 0; }
.period { font-size: 16px; color: #666; margin-left: 4px; }
.billed-yearly-container { min-height: 20px; display: flex; align-items: center; justify-content: center; width: 100%; }
.billed-yearly { margin: 0; color: #666; font-size: 14px; text-align: center; width: 100%; }
.plan:not(:first-child) .billed-yearly { margin-top: 2px; }
.plan[data-v-69097ff2]:not(:first-child) .features ul[data-v-69097ff2] { background-color: white; }
.features ul[data-v-69097ff2] { background-color: #f8f9fa; }
.plan[data-v-69097ff2]:first-child .features ul[data-v-69097ff2] li { border-bottom: 1px solid #eceef0; }
.plan[data-v-69097ff2]:first-child .features ul[data-v-69097ff2] li:last-child { border-bottom: none; }
.features[data-v-69097ff2] { padding: 0; margin-top: 20px; }
.plan[data-v-69097ff2]:first-child .features[data-v-69097ff2] { background-color: #f8f9fa; }
.plan[data-v-69097ff2]:not(:first-child) .features[data-v-69097ff2] { background-color: white; }
.features[data-v-69097ff2] ul li { border: none !important; }
.plan[data-v-69097ff2]:first-child .features[data-v-69097ff2] ul li { border: none !important; }
.plan[data-v-69097ff2]:last-child { border: 1px solid #e0e0e0; }
.popular[data-v-69097ff2] { border: none; position: relative; background: white; }
.popular .get-started-btn { position: relative; overflow: hidden; }
.popular .get-started-btn::after { content: ''; position: absolute; top: -50%; left: -50%; width: 200%; height: 200%; background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.3), transparent); transform: rotate(45deg); animation: shine 8s cubic-bezier(0.4, 0, 0.2, 1) infinite; }
.popular .get-started-btn:hover::after { animation: none; transform: translateX(100%) rotate(45deg); }
.plan:last-child .get-started-btn { background: transparent; border: none; color: #ff6b6b; box-shadow: none; position: relative; }
.plan:last-child .get-started-btn::before { content: ''; position: absolute; top: 0; left: 0; right: 0; bottom: 0; border-radius: 8px; padding: 2px; background: linear-gradient(to right, #ff6b6b, #a66cff); -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0); mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0); -webkit-mask-composite: xor; mask-composite: exclude; }
.plan:last-child .get-started-btn:hover { background: linear-gradient(to right, rgba(255, 107, 107, 0.1), rgba(166, 108, 255, 0.1)); transform: translateY(-2px); opacity: 1; }
.plan:not(:last-child) .get-started-btn { background: linear-gradient(to right, #ff6b6b, #a66cff); color: white; border: none; }
.mainland-popup .payment-options { margin: 20px 0; text-align: left; }
.mainland-popup .payment-title { font-size: 16px; font-weight: 500; margin-bottom: 12px; color: #333; text-align: left; }
.mainland-popup .payment-plan-list { display: flex; flex-direction: column; gap: 12px; }
.mainland-popup .plan-option { display: flex; align-items: center; gap: 8px; cursor: pointer; }
.mainland-popup .plan-option input[type="radio"] { margin: 0; }
.mainland-popup .plan-label { font-size: 14px; color: #333; }
.mainland-popup .payment-text { margin-bottom: 10px; display: flex; align-items: center; flex-wrap: nowrap; line-height: 1.6; white-space: nowrap; overflow: hidden; }
.mainland-popup .payment-icon { display: inline-flex; align-items: center; justify-content: center; height: 20px; margin: 0 4px; }
.mainland-popup .payment-icon img { width: 16px; height: 16px; display: block; }

@media (max-width: 1000px) {
  .pricing-header { flex-direction: column; align-items: flex-start; gap: 20px; } 
  h1 { font-size: 32px; }
  .billing-toggle { align-self: center; } 
  .pricing-plans { flex-direction: column; gap: 40px; } 
  .plan { max-width: 100%; width: 100%; margin-bottom: 0; }
}

@media (max-width: 920px) { 
  .pricing-container { padding: 10px 20px; } 
  .popular { transform: scale(1.01); box-shadow: 0 4px 12px rgba(0,0,0,0.08); }
}

@media (max-width: 768px) {
  .pricing-header { flex-direction: column; align-items: center; }
  .pricing-title { text-align: center; margin-bottom: 40px; display: none; display: block; margin-bottom: 16px; }
  .pricing-title h1 { font-size: 22px; }
  .billing-toggle-wrapper { width: 100%; justify-content: center; padding: 0 20px; }
  .billing-toggle { flex-direction: column; gap: 8px; }
  .toggle-text-right { flex-direction: column; align-items: center; gap: 8px; } 
  .yearly-discount { margin-top: 4px; } 
  .popular { transform: scale(1.02); }
  .billing-toggle { flex-direction: row; height: 38px; padding: 5px; max-width: 320px; gap: 0; }
  .toggle-text-left, .toggle-text-right { flex: 1; text-align: center; padding: 0 16px; min-width: auto; } 
  .toggle-text-right { flex-direction: row; justify-content: flex-start; align-items: center; gap: 8px; }
  .switch-container { flex: 0 0 60px; margin: 0; }
  .yearly-discount { margin-left: 8px !important; font-size: 12px !important; padding: 2px 8px !important; }
  .pricing-plans { margin-bottom: 60px; }


}

</style>
