<template>
  <div class="container">
    <div class="top" id="bloc-0">
      <voice-header></voice-header>
    </div>
    
    <div class="main api-intro-page">
      <!-- Hero Section -->
      <div class="hero-section">
        <div class="hero-content">
          <h1>{{ $t('api_intro.heroTitle') }}</h1>
          <p class="hero-subtitle">{{ $t('api_intro.heroSubtitle') }}</p>
          <div class="cta-buttons">
            <router-link :to="'/' + $route.params.locale + '/pricing'" class="btn-primary">{{ $t('api_intro.viewPricing') }}</router-link>
          </div>
        </div>
      </div>

      <!-- Features Section -->
      <section class="about-section features-section">
        <div class="feature-row">
          <div class="feature-text">
            <h2>{{ $t('api_intro.featureEngine.title') }}</h2>
            <h3>{{ $t('api_intro.featureEngine.desc') }}</h3>
            <div class="feature-list">
              <h3 v-for="(point, index) in $t('api_intro.featureEngine.points')" :key="index" class="list-item">{{ point }}</h3>
            </div>
          </div>
          <div class="feature-image">
            <img src="/ssr/img/feature-engine.webp" alt="High Performance Engine" height="300px" width="350px" loading="lazy" >
          </div>
        </div>
        
        <div class="feature-row reverse">
          <div class="feature-text">
            <h2>{{ $t('api_intro.featureLanguage.title') }}</h2>
            <h3>{{ $t('api_intro.featureLanguage.desc') }}</h3>
            <div class="feature-list">
              <h3 v-for="(point, index) in $t('api_intro.featureLanguage.points')" :key="index" class="list-item">{{ point }}</h3>
            </div>
          </div>
          <div class="feature-image">
            <img src="/ssr/img/feature-languages.webp" alt="Language Support" height="300px" width="350px" loading="lazy" >
          </div>
        </div>

        <div class="feature-row">
          <div class="feature-text">
            <h2>{{ $t('api_intro.featureSocial.title') }}</h2>
            <h3>{{ $t('api_intro.featureSocial.desc') }}</h3>
            <div class="feature-list">
              <h3 v-for="(point, index) in $t('api_intro.featureSocial.points')" :key="index" class="list-item">{{ point }}</h3>
            </div>
          </div>
          <div class="feature-image">
            <img src="/ssr/img/usecase-video.webp" alt="Social Media Content" height="300px" width="350px" loading="lazy" >
          </div>
        </div>
        
        <div class="feature-row reverse">
          <div class="feature-text">
            <h2>{{ $t('api_intro.featureLearning.title') }}</h2>
            <h3>{{ $t('api_intro.featureLearning.desc') }}</h3>
            <div class="feature-list">
              <h3 v-for="(point, index) in $t('api_intro.featureLearning.points')" :key="index" class="list-item">{{ point }}</h3>
            </div>
          </div>
          <div class="feature-image">
            <img src="/ssr/img/usecase-education.webp" alt="E-Learning Solutions" height="300px" width="350px" loading="lazy" >
          </div>
        </div>

        <div class="feature-row">
          <div class="feature-text">
            <h2>{{ $t('api_intro.featureService.title') }}</h2>
            <h3>{{ $t('api_intro.featureService.desc') }}</h3>
            <div class="feature-list">
              <h3 v-for="(point, index) in $t('api_intro.featureService.points')" :key="index" class="list-item">{{ point }}</h3>
            </div>
          </div>
          <div class="feature-image">
            <img src="/ssr/img/usecase-service.webp" alt="AI Customer Service" height="300px" width="350px" loading="lazy" >
          </div>
        </div>
      </section>

      <!-- Integration Steps Section -->
      <section class="about-section integration-steps-section">
        <h2>{{ $t('api_intro.integration.title') }}</h2>
        <div class="steps-container">
          <div class="step-item" v-for="(step, index) in $t('api_intro.integration.steps')" :key="index">
            <div class="step-number">{{ index + 1 }}</div>
            <h3>{{ step.title }}</h3>
            <p>{{ step.desc }}</p>
          </div>
        </div>
      </section>
    </div>

    <div class="footer">
      <voice-footer></voice-footer>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import VoiceHeader from '../components/VoiceHeader.vue'

const VoiceFooter = defineAsyncComponent(() => import('../components/VoiceFooter.vue'))

export default {
  name: 'ApiIntro',
  components: {
    VoiceHeader,
    VoiceFooter
  },
  head() {
    return {
      title: this.$t('api_intro.headTitle'),
      keywords: this.$t('api_intro.headKeywords'),
      description: this.$t('api_intro.headDescription'),
      link: [
        { 
          rel: 'stylesheet', 
          href: '/ssr/css/second_style.css',
          id: 'second-style',
          media: 'all'
        }
      ]
    }
  },
  metaInfo() {
    return {
      link: [
        { rel: 'alternate', hreflang: 'x-default', href: 'https://tiktokvoice.net/en/api-intro' },
        { rel: 'alternate', hreflang: 'en', href: 'https://tiktokvoice.net/en/api-intro' },
        { rel: 'alternate', hreflang: 'ja', href: 'https://tiktokvoice.net/ja/api-intro' },
        { rel: 'alternate', hreflang: 'zh', href: 'https://tiktokvoice.net/zh/api-intro' },
        { rel: 'alternate', hreflang: 'zh-tw', href: 'https://tiktokvoice.net/zh-tw/api-intro' },
        { rel: 'alternate', hreflang: 'ko', href: 'https://tiktokvoice.net/ko/api-intro' },
        { rel: 'alternate', hreflang: 'vi', href: 'https://tiktokvoice.net/vi/api-intro' },
        { rel: 'alternate', hreflang: 'th', href: 'https://tiktokvoice.net/th/api-intro' },
        { rel: 'alternate', hreflang: 'hi', href: 'https://tiktokvoice.net/hi/api-intro' },
        { rel: 'alternate', hreflang: 'fa', href: 'https://tiktokvoice.net/fa/api-intro' },
        { rel: 'alternate', hreflang: 'ru', href: 'https://tiktokvoice.net/ru/api-intro' },
        { rel: 'alternate', hreflang: 'de', href: 'https://tiktokvoice.net/de/api-intro' },
        { rel: 'alternate', hreflang: 'fr', href: 'https://tiktokvoice.net/fr/api-intro' },
        { rel: 'alternate', hreflang: 'ro', href: 'https://tiktokvoice.net/ro/api-intro' },
        { rel: 'alternate', hreflang: 'cs', href: 'https://tiktokvoice.net/cs/api-intro' },
        { rel: 'alternate', hreflang: 'es', href: 'https://tiktokvoice.net/es/api-intro' },
        { rel: 'alternate', hreflang: 'pt', href: 'https://tiktokvoice.net/pt/api-intro' },
        { rel: 'alternate', hreflang: 'bn', href: 'https://tiktokvoice.net/bn/api-intro' },
        { rel: 'alternate', hreflang: 'it', href: 'https://tiktokvoice.net/it/api-intro' },
        { rel: 'alternate', hreflang: 'ar', href: 'https://tiktokvoice.net/ar/api-intro' },
        { rel: 'alternate', hreflang: 'ur', href: 'https://tiktokvoice.net/ur/api-intro' },
        { rel: 'alternate', hreflang: 'ms', href: 'https://tiktokvoice.net/ms/api-intro' },
        { rel: 'alternate', hreflang: 'tr', href: 'https://tiktokvoice.net/tr/api-intro' },
        { rel: 'alternate', hreflang: 'pl', href: 'https://tiktokvoice.net/pl/api-intro' },
        { rel: 'alternate', hreflang: 'nl', href: 'https://tiktokvoice.net/nl/api-intro' },
        { rel: 'alternate', hreflang: 'uk', href: 'https://tiktokvoice.net/uk/api-intro' },
      ]
    }
  }
}
</script>

<style scoped>
@import url('/ssr/css/api_intro.css');

html, body { margin: 0; padding: 0; overflow-x: hidden; width: 100%; max-width: 100%; }
.container { width: 100%; max-width: 100%; min-height: 100vh; background: #ffffff; margin: 0; padding: 0; overflow-x: hidden; position: relative; box-sizing: border-box; }
.main.api-intro-page { width: 100%; max-width: 100%; margin: 0; padding: 0; overflow-x: hidden; box-sizing: border-box; }
.hero-section { position: relative; text-align: center; padding: 100px 0 80px; overflow: hidden; width: 100%; left: 0; right: 0; background: linear-gradient(180deg, rgba(255,255,255,0.95) 0%, rgba(255,255,255,0.98) 100%); }

.hero-content { position: relative; z-index: 2; width: 100%; margin: 0 auto; padding: 0 12%; }
.hero-section h1 { font-size: 3.5rem; font-weight: 700; margin-bottom: 20px; background: linear-gradient(45deg, #2196F3, #4CAF50); background-clip: text; -webkit-background-clip: text; -webkit-text-fill-color: transparent; }
.hero-subtitle { font-size: 1.5rem; color: #666; margin-bottom: 40px; }
.cta-buttons { display: flex; gap: 20px; justify-content: center; max-width: 100%; box-sizing: border-box; padding: 0 20px; }
.btn-primary, .btn-secondary { padding: 15px 40px; border-radius: 8px; font-weight: 600; text-decoration: none; transition: all 0.3s ease; min-width: 180px; text-align: center; white-space: nowrap; display: inline-flex; align-items: center; justify-content: center; }
.btn-primary { background: #0C74CA; color: white !important; }
.btn-primary:hover { background: #1976D2; transform: translateY(-2px); }
.btn-secondary { border: 2px solid #2196F3; color: #2196F3 !important; }
.btn-secondary:hover { background: rgba(33, 150, 243, 0.1); transform: translateY(-2px); }

.container {
  width: 100%;
  min-height: 100vh;
  background: #ffffff;
}

.main {
  width: 100%;
}

.code-sample {
  width: 100%;
  max-width: 800px;
  margin: 30px auto;
  background: #1e1e1e;
  border-radius: 12px;
  padding: 20px;
  overflow-x: auto;
}

.code-sample code {
  color: #fff;
  font-family: 'Fira Code', monospace;
}

@media (max-width: 768px) {
  .hero-section { padding: 80px 0 60px; }
  .hero-content { padding: 0 6%; }
  .hero-section h1 { font-size: 2.5rem; margin-bottom: 15px; }
  .hero-subtitle { font-size: 1.2rem; margin-bottom: 30px; padding: 0 20px; }
  .cta-buttons { flex-direction: column; align-items: stretch; gap: 15px; padding: 0 30px; }
  .btn-primary, .btn-secondary { width: 100%; min-width: unset; padding: 16px 20px; font-size: 1.1rem; }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .hero-section { padding: 90px 0 70px; }
  .hero-content { padding: 0 8%; }
  .hero-section h1 { font-size: 3rem; }
  .hero-subtitle { font-size: 1.3rem; }
  .cta-buttons { padding: 0 30px; }
  .btn-primary, .btn-secondary { min-width: 200px; padding: 15px 30px; }
}

</style>
