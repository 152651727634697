import { setCookie, delCookie } from './cookies'
import { reportError } from './errorReporter'
import axios from 'axios'
export const handleGoogleAuth = {
  async handleCredentialResponse(response, store, apiUrl) {
    console.log("auth handleCredentialResponse 11, response:", response)
    if (response.credential) {
      console.log("auth handleCredentialResponse 22, response:", response.credential)
      return await this.sendTokenToBackend(response.credential, store, apiUrl)
    }
    return false
  },

  async sendTokenToBackend(token, store, apiUrl) {
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token })
      });

      if (response.ok) {
        const data = await response.json()
        if (data.ret == 0) {
          const userData = {
            'email': data.email,
            'name': data.full_name,
            'picture': data.picture,
          };
          // 存储到 Vuex
          store.dispatch('loginUser', userData)
          // 存储到 cookie
          setCookie('userData', JSON.stringify(userData))
          return true
        } else {
          reportError(new Error('Backend authentication error'), JSON.stringify({
            method: 'sendTokenToBackend',
            reqid: data.reqid,
            ret: data.ret,
            msg: data.msg
          }))
          this.handleLogoutCleanup(store)
          return false
        }
      } else {
        reportError(new Error('Backend authentication failed'), JSON.stringify({
          method: 'sendTokenToBackend',
          status: response.status
        }))
        this.handleLogoutCleanup(store)
        return false
      }
    } catch (error) {
      reportError(error, 'sendTokenToBackend, Error sending token to backend')
      this.handleLogoutCleanup(store)
      return false
    }
  },

  handleLogoutCleanup(store) {
    store.dispatch('logoutUser')
    delCookie('userData')
  },

  async logout(store) {
    // 清除 Vuex store 中的用户数据
    store.dispatch('logoutUser')
    // 清除 cookie
    delCookie('userData')
    // 返回成功状态
    return true
  },
}