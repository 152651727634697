<template>
  <footer>
    <div class="footer_div">
      <div class="footer_div_last">
        <a href="/en/privacy-policy" title="TikTok Voice privacy policy"  class="footer-link-last">{{ $t('comm.privacy_policy') }}</a>
        <a href="/en/terms-of-service" title="TikTok Voice term of service"  class="footer-link-last">{{ $t('comm.terms_conditions') }}</a>
        <a class="footer-link-last" title="TikTok tts Pricing" :href="pricing_href" rel="nofollow">
          {{ $t('comm.pricing') }}
        </a>
        <span class="footer-link-last">{{ $t('comm.footer_help') }} tiktokaivoicetool@gmail.com</span>
      </div>
    </div>
    
    <div class="copyright">
      <span>&copy; {{ currentYear }} TikTokVoice.net All rights reserved.</span>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'VoiceFooter',
  data () {
    return {
      pricing_href: '/en/pricing',
    }
  },
  computed: {
    sendmail() {
      return `mailto:${this.$i18n.t('comm.footer_mail')}`
    },
    currentYear() {
      return new Date().getFullYear()
    }
  },
  mounted () {
    let lang = this.$store.state.lang
    this.pricing_href = `/${lang}/pricing`
  }
}
</script>
<style scoped>
.copyright {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 0.5rem; 
}
</style>
