<template>
<div>
  <nav class="header-nav">
    <div class="ph-header-first">
      <div class="logo_div">
        <a  href="/" title="tik tok voice logo" aria-label="TikTok AI Voice" class="logo-link">
          <img src="/ssr/img/tiktok_voice_logo.svg" title="TikTok text to speech" alt="TikTok tts logo" height="40px" width="50px" class="top_left_logo" />
          <img src="/ssr/img/tiktok_voice_word.svg" loading="lazy" title="TikTok AI Voice Generator" alt="TikTok AI Voice Generator logo" height="40px" width="120px" class="top_left_logo"/>
        </a>
      </div>
      <div v-if="isMobile" class="lg-hidden">
        <button type="button" class="ph-menu-button" aria-label="TikTok tts menu" @click="toggleMenu">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon" style="width: 24px; height: 24px">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5">
            </path>
          </svg>
        </button>
      </div>
    </div>
    <ul class="main-nav" id="js-menu" v-show="!isMobile">
      <li><a class="nav-links" title="TikTok Voice Generator" :href="home_href">{{ $t('comm.home') }}</a></li>
      <li><a class="nav-links" title="TikTok Sounds Effect" :href="sounds_href">{{ $t('comm.sounds') }}</a></li>
      <li><a class="nav-links" title="TikTok AI Voice Clone" :href="voice_clone_href">{{ $t('comm.voice_cloning') }}</a></li>
      <li><a class="nav-links" title="TikTok Text to Speech API" :href="api_href">{{ $t('comm.api_intro') }}</a></li>
      <!-- li class="pricing-nav-item">
        <a class="nav-links" title="TikTok tts Pricing" :href="pricing_href">
          <span class="pricing-text">{{ $t('comm.pricing') }}</span>
        </a>
      </li -->
      <li><a class="nav-links" title="About TikTok Voice Generator" :href="about_href">{{ $t('comm.about_us') }}</a></li>
    </ul>
    <div class="nav-right" v-show="!isMobile">
      <div class="mobile-divider"></div>
      <label class="nav-right-label" aria-label="tik tok voice generater language">
        <select class="top_right_select" v-model="value" v-on:change="changeLang" aria-label="tiktok tts" v-bind:placeholder="$t('comm.select_language')">
          <option v-for="item in languageOptions" v-bind:key="item.value" v-bind:label="item.label" v-bind:value="item.value"></option>
        </select>
      </label>
      <div v-if="!isLoggedIn">
           <button class="sign-in-btn" v-on:click="showSignIn"> {{ $t('comm.login') }} </button>
      </div>
      <div class="user_menu" v-else>
        <button class="user_button" @click="toggleUserMenu">
          <img :src="currentUser.picture" alt="TikTok Voice gentor" loading="lazy" height="24" width="24" class="user_avatar" />
          <span class="user_name"> {{  currentUser.name }}</span>
          <svg class="dropdown-icon" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
               <polyline points="6 9 12 15 18 9"></polyline>
          </svg>
        </button>
        <div class="dropdown-menu" id="pcUserMenu" v-show="isUserMenuOpen" >
           <a title="tiktok text to speach settings" :href="settings_href">{{ $t('setting.setting') }}</a>
           <a title="tiktok text to speach logout" href="#" @click="logout">{{ $t('comm.logout') }}</a>
        </div>
      </div>
    </div>
  </nav>
  <div class="mobile-menu-overlay" v-show="isMobile && isMenuOpen" @click="toggleMenu"> </div>
  <div class="mobile-menu" :class="{ 'open': isMobile && isMenuOpen, 'instant-close': isInstantClose }">
      <ul class="main-nav">
        <li class="nav-item">
          <a class="nav-links" title="TikTok Voice Generator" :href="home_href" @click.prevent="navigateAndCloseMenu('home')">
            {{ $t('comm.home') }}
            <span class="nav-arrow">›</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-links" title="TikTok Sounds Effect" :href="sounds_href" @click.prevent="navigateAndCloseMenu('sounds')">
            {{ $t('comm.sounds') }}
            <span class="nav-arrow">›</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-links" title="TikTok AI Voice Clone" :href="voice_clone_href" @click.prevent="navigateAndCloseMenu('voiceClone')">
            {{ $t('comm.voice_cloning') }}
            <span class="nav-arrow">›</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-links" title="TikTok tts API" :href="api_href" @click.prevent="navigateAndCloseMenu('api')">
            {{ $t('comm.api_intro') }}
            <span class="nav-arrow">›</span>
          </a>
        </li>
        <!-- li class="nav-item">
          <a class="nav-links" title="TikTok tts Pricing" :href="pricing_href" @click.prevent="navigateAndCloseMenu('pricing')">
            <span class="pricing-text">{{ $t('comm.pricing') }}</span>
            <span class="nav-arrow">›</span>
          </a>
        </li -->
        <li class="nav-item">
          <a class="nav-links" title="About TikTok Voice Generator" :href="about_href" @click.prevent="navigateAndCloseMenu('about')">
            {{ $t('comm.about_us') }}
            <span class="nav-arrow">›</span>
          </a>
        </li>
      </ul>
      <div class="nav-right">
        <div class="mobile-divider"></div>
        <label class="nav-right-label">
          <select class="top_right_select" v-model="value" v-on:change="changeLang" aria-label="tiktok voice generator" v-bind:placeholder="$t('comm.select_language')">
            <option v-for="item in languageOptions" v-bind:key="item.value" v-bind:label="item.label" v-bind:value="item.value"></option>
          </select>
        </label>
        <div v-if="!isLoggedIn">
             <button class="sign-in-btn" v-on:click="showSignIn">{{ $t('comm.login') }}</button>
        </div>
        <div class="user_menu" v-else @click.stop>
          <button class="user_button" @click.stop="handleUserMenuInteraction" @touchstart.prevent="handleUserMenuInteraction">
            <img :src="currentUser.picture" alt="TikTok Voice gentor" loading="lazy" height="24" width="24" class="user_avatar" />
            <span class="user_name"> {{  currentUser.name }}</span>
            <svg class="dropdown-icon" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                 <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </button>
          <div class="dropdown-menu" id="userMenu"  v-show="isUserMenuOpen" @click.stop style="z-index: 1000;">
             <a title="tiktok tts settings" :href="settings_href">{{ $t('setting.setting') }}</a>
             <a title="tiktok text to speech logout" href="#" @click.stop="logout">{{ $t('comm.logout') }}</a>
          </div>
        </div>
      </div>
  </div>
  <GoogleSignInModal 
      :visible.sync="isSignInVisible"
      @credential-response="handleCredentialResponse"
    />
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { loadLanguageAsync } from '../lang';
import { handleGoogleAuth } from '../utils/auth'
import { reportError } from '../utils/errorReporter'
import debounce from 'lodash/debounce';
import { LANGUAGE_OPTIONS } from '../utils/languageOptions';
import { setCookie, delCookie, getCookie } from '../utils/cookies';

export default {
  name: 'VoiceHeader',
  components: {
    GoogleSignInModal: () => import('./GoogleSignInModal.vue')
  },
  data () {
    return {
      isSignInVisible: false,
      isMobile: true,
      isMenuOpen: false,
      value: 'en', // 默认语言
      home_href: '/',
      pricing_href: '/en/pricing',
      settings_href: '/en/settings',
      isUserMenuOpen: false,
      debouncedCheckMobile: null,
      isInstantClose: false,
      debouncedToggleUserMenu: null,
      voice_clone_href: '/en/voice-cloning',
      sounds_href: '/en/sounds-effect',
      api_href: '/en/api-intro',
      about_href: '/en/about',
      resizeObserver: null,
      host: 'https://tiktokvoice.net'
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'currentUser']),
    languageOptions() {
      return LANGUAGE_OPTIONS
    }
  },
  methods: {
    async changeLang (evt) {
      var lang = evt.target.value
      setCookie('locale', lang)
      this.value = lang
      const currentPath = this.$route.path
      const pathParts = currentPath.split('/').filter(Boolean)
      const availableLocales = this.languageOptions.map(option => option.value)

      var newPath
      if (pathParts.length <= 1) {
        if (availableLocales.includes(pathParts[0])) {
          newPath = lang === 'en' ? '/' : `/${lang}`
        } else if (pathParts[0]) {
          newPath = currentPath
        } else {
          // 如果没有值，使用新语言
          newPath = lang === 'en' ? '/' : `/${lang}`
        }
      } else {
        if (availableLocales.includes(pathParts[0])) {
          // 如果第一部分是语言，直接替换
          newPath = currentPath.replace(pathParts[0], lang)
        } else {
          // 如果第一部分不是语言，保留原值并在前面添加新语言（除非是英语）
          newPath =  currentPath
        }
      }
      window.location.href = newPath
    },
    navigateTo(destination) {
      var targetPath;
      switch(destination) {
        case 'home':
          targetPath = this.home_href;
          break;
        case 'pricing':
          targetPath = this.pricing_href;
          break;
        case 'voiceClone':
          targetPath = this.voice_clone_href;
          break;
        case 'sounds':
          targetPath = this.sounds_href;
          break
        case 'api':
          targetPath = this.api_href;
          break;
        case 'about':
          targetPath = this.about_href;
          break;
      }
      if (this.$route.path !== targetPath) {
        this.$router.push(targetPath).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            reportError(err, 'navigateTo');
          }
        });
      }
      if (this.isMobile) {
        this.isMenuOpen = false;
      }
    },
    handleUserMenuInteraction(event) {
      event.preventDefault();
      event.stopPropagation();
      this.toggleUserMenu();
    },
    updateLinks(lang) {
      this.pricing_href = `/${lang}/pricing`
      this.settings_href = `/${lang}/settings`
      this.voice_clone_href = `/${lang}/voice-cloning`
      this.sounds_href = `/${lang}/sounds-effect`
      this.api_href = `/${lang}/api-intro`
      this.about_href = `/${lang}/about`
      this.home_href = lang === 'en' ? '/' : `/${lang}`
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },
    checkMobile() {
      requestAnimationFrame(() => {
        this.isMobile = window.innerWidth <= 900; // 和样式保存一致
        this.isMenuOpen = false;
      });
    },
    showSignIn: function () {
      this.isSignInVisible = true
    },
    closeSignIn: function () {
      this.isSignInVisible = false
    },
    toggleUserMenu() {
      this.isUserMenuOpen = !this.isUserMenuOpen;
    },
    closeUserMenu() {
      this.isUserMenuOpen = false;
    },
    // toggleUserMenu 的实际现
    actualToggleUserMenu() {
      this.isUserMenuOpen = !this.isUserMenuOpen;
    },
    async handleCredentialResponse(response) {
      const apiUrl = this.host + "/lapi/auth/google"
      const success = await handleGoogleAuth.handleCredentialResponse(response, this.$store, apiUrl)
      this.closeSignIn()
      if (!success) {
        this.$emit('login-error', 'Authentication failed')
      }
    },
    async logout() {
      await handleGoogleAuth.logout(this.$store)
      // 重新加载页面或执行其他UI相关操作
      this.isSignInVisible = false;
      location.reload();
    },
    navigateAndCloseMenu(destination) {
      this.isInstantClose = true;
      this.isMenuOpen = false;
      this.$nextTick(() => {
        this.navigateTo(destination);
        // 重置 isInstantClose，为下次打开做准备
        setTimeout(() => {
          this.isInstantClose = false;
        }, 50);
      });
    },
  },
  mounted () {
    this.checkMobile()
    this.debouncedCheckMobile = debounce(this.checkMobile, 50,);
    this.debouncedToggleUserMenu = debounce(this.actualToggleUserMenu, 50); // 对 toggleUserMenu 进行防抖处理

    // 使用 ResizeObserver 替代 resize 事件
    this.resizeObserver = new ResizeObserver(this.debouncedCheckMobile);
    this.resizeObserver.observe(document.body);
    // window.addEventListener('click', this.closeUserMenu);

    this.value = this.$store.state.lang
    this.updateLinks(this.$store.state.lang)
  },
  beforeUnmount() {
    // 清理 ResizeObserver
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    // 清理防抖函数
    if (this.debouncedCheckMobile) {
      this.debouncedCheckMobile.cancel();
    }
    if (this.debouncedToggleUserMenu) {
      this.debouncedToggleUserMenu.cancel();
    }
  }
}

</script>

<style scoped>
.nav-item .nav-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header-nav { position: relative; width: 100%; display: flex;  justify-content: space-between;  align-items: center; height: 50px; z-index: 1000; background-color: #ffffff; white-space: nowrap; overflow: visible; padding: 0 20px;}
.logo_div{ margin-left: 60px; }
.lg-hidden { display: none; }

.main-nav { display: flex; list-style-type: none; margin: 0; padding: 0; flex: 1; justify-content: center; gap: 10px;}
.nav-right { display: flex; align-items: center; flex-wrap: nowrap; }
.nav-right-label { display: flex; align-items: center; flex-wrap: nowrap; margin-right: 2px; height: 42px; }
.sign-in-btn { height: auto;  width: 80px; font-size: 16px; border: 1px solid #DADED6FF; border-radius: 4px; cursor: pointer; background-color: #fff; margin-right: 15px; line-height: 1.5;  padding: 5px 10px;}
.sign-in-btn:hover { background-color: #fe2c56e1;color: #ffffff; }

.mobile-menu-overlay { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background-color: rgba(0, 0, 0, 0.5); z-index: 998; }
.mobile-menu { display: none; position: fixed; top: 0; left: -100%; width: 80%; max-width: 300px; height: 100%; background-color: white; z-index: 999; transition: left 0.3s ease-out; overflow-y: auto; margin-bottom: 10px; padding: 40px; box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); }
.mobile-menu.open { left: 0; }

.signCover { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background-color: rgba(0, 0, 0, 0.5); display: flex; justify-content: center; align-items: center; z-index: 1000; }

.nav-item .pricing-text {
  order: 1;
}

.nav-item .fri-badge {
  order: 2;
  margin: 0 8px;
}

.nav-item .nav-arrow {
  order: 3;
}

@media (max-width:1024px){ 
  .header-nav{padding:0 10px} 
  .logo_div{margin-left:10px}
}

@media (max-width: 900px) {
  .header-nav { flex-direction: column; align-items: flex-start; position: relative; height: 50px; }
  .ph-header-first { display:flex; flex-direction: row; justify-content:space-between; align-items: center; width: 100%; flex-wrap: nowrap; }
  .logo_div{ margin-left: 15px; }
  .lg-hidden { display: block; }
  .ph-menu-button { background: none; border: none; cursor: pointer; padding: 5px; }

  .mobile-menu .nav-right { display: flex; flex-direction: column; align-items: center; width: 100%; padding: 0 20px; }
  .mobile-menu .nav-right-label { display: flex; justify-content: center; align-items: center; width: 100%; margin-bottom: 15px; }
  .nav-right { position: relative; padding-top: 1px; margin-bottom: 0.5rem; }
  .nav-right-label { display: flex; align-items: center; margin-top: 1.25rem;}
  .mobile-divider { position: absolute; left: 0;  right: 0;  top: 0; height: 1px; background-color: #fff; }

  .sign-in-btn { margin-left: 1.5rem; margin-top: 1.25rem; }
  .mobile-menu { display: block; padding-top: 50px; }
  .mobile-menu .top_right_select, .mobile-menu .sign-in-btn { width: 100%;  max-width: 200px;  min-width: 150px; }
  .mobile-menu .sign-in-btn { display: block; width: 100%; max-width: 200px; min-width: 150px; margin: 10px auto; padding: 10px 15px; text-align: center; height: auto; background-color: #fe2c56e1;  color: #FFFFFF; border: none; border-radius: 4px; font-weight: 500; transition: background-color 0.3s ease; }
  .mobile-menu .sign-in-btn:hover { background-color: #fe2c56c0; }
  .mobile-menu .nav-item { width: 100%; margin: 0; }
  .mobile-menu .nav-arrow { font-size: 24px; color: #999; }
  .mobile-menu .fri-badge { margin: 0 12px; font-size: 12px; order: 0; }
  .mobile-menu .top_right_select { padding: 8px 0; background-position: right 10px center; height: auto;  margin: 0; width: auto;  text-align: center; }

  .nav-item .fri-badge {
    margin: 0 8px 0 auto;
  }
}

.pricing-nav-item .nav-links {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.pricing-text {
  display: inline-block;
}

@media (max-width: 768px) {
  .nav-links {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .fri-badge {
    margin-right: 4px;
  }
  
}

@media (max-width: 480px) {
  .fri-badge {
    font-size: 10px;
    padding: 1px 3px;
    margin-left: 2px;
    background-color: #FF4D4F;
    color: #fff;
  }
}

@media (max-width: 380px) {
  .fri-badge {
    font-size: 9px;
    padding: 1px 2px;
    margin-left: 1px;
    background-color: #FF4D4F;
    color: #fff;
  }
}

.mobile-menu .nav-item {
  margin: 0;
  padding: 0;
}

.mobile-menu .nav-links {
  padding: 0.5rem 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 44px;
}
</style>
